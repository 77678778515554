import { useEffect } from "preact/hooks";

const BatteryBar = () => {
    const postfix = Math.random().toString().substr(2, 8);
    const newId = `${postfix}-demand`;

    useEffect(() => {
        const barPercentage = Math.random() * 10;

        const demandBarDiv = document.getElementById(newId);

        // Function to add demand bars
        for (var i = 0; i < 10; i++) {
            const demandBar = document.createElement("div");
            demandBar.classList.add("barSingle");
            demandBar.style.width = "3px";
            demandBar.style.height = "6.6px";
            demandBar.style.listStyle = "9px";
            demandBar.style.marginRight = "2px";

            const barNumber = Math.round(barPercentage);

            if (i <= barNumber) {
                // if (barNumber < 3) {
                //     demandBar.style.backgroundColor = "#FF4136";
                // } else if (barNumber < 5) {
                //     demandBar.style.backgroundColor = "#ff851d";
                // } else {
                // demandBar.style.backgroundColor = "#00B012";
                // }
                demandBar.style.backgroundColor = "#25CA83";
            } else {
                demandBar.style.backgroundColor = "#fff";
            }

            demandBarDiv.appendChild(demandBar);
        }
    }, []);

    return <div id={newId} className="qx-widget-demandBarDiv"></div>;
};

export default BatteryBar;
