import BatteryBar from "./BatteryBar";
import analyticsRust from "../../analyticsRust";

const DemandSection = ({ url, price, options }) => {
    return (
        <div
            className="qx-widget-demand-left"
            onClick={() => {
                analyticsRust.graphEvent({
                    articleUrl: url,
                    subtype: "click",
                    price,
                    element: "demand",
                });
            }}
        >
            <span>Demand</span>
            <BatteryBar />
        </div>
    );
};

export default DemandSection;
