class Analytics {
    constructor(url) {
        this.url = url;
        this.user_id = null;
    }

    async trackEvent(eventName, eventProps) {
        if (!this.user_id) {
            this.user_id = localStorage.getItem("qxUserId");
        }

        const payload = {
            user_id: this.user_id,
            device: {
                width: window.screen.width,
                height: window.screen.height,
            },
            type: eventName,
            url: eventProps.url,
            referrer: eventProps.referrer,
        };

        return new Promise((resolve, reject) => {
            fetch(this.url + "event", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "user-id": `${this.user_id}`,
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    this.user_id = res.user_id;
                    localStorage.setItem("qxUserId", this.user_id);

                    resolve(res.event_id);
                })
                .catch((err) => reject(err));
        });
    }

    pollEvent(eventId) {
        const payload = {
            type: "poll",
            id: eventId,
        };
        fetch(this.url + "event", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-id": `${this.user_id}`,
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify(payload),
        });
    }

    async trackPageView(url, currency) {
        const payload = { url, currency, referrer: document.referrer || null };

        const eventId = await this.trackEvent("pageview", payload);
        setInterval(() => {
            this.pollEvent(eventId);
        }, 5000);
    }

    async trackArticleHover(eventType, url, price, currency) {
        const payload = { url, price, currency };
        this.trackEvent(eventType, payload);
    }

    async graphEvent({ articleUrl, subtype, element, price, currency }) {
        let payload;
        if (subtype === "open") {
            payload = {
                pageUrl: window.location.href,
                articleUrl,
                type: "graph",
                subtype,
                price,
                currency,
            };
        } else {
            payload = {
                pageUrl: window.location.href,
                articleUrl,
                type: "graph",
                subtype,
                element,
            };
        }

        return new Promise((resolve, reject) => {
            fetch(this.url + "event", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "user-id": `${this.user_id}`,
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    this.user_id = res.user_id;
                    localStorage.setItem("qxUserId", this.user_id);
                    resolve(res.event_id);
                })
                .catch((err) => reject(err));
        });
    }
}

const analyticsRust = new Analytics("https://a.kunato.io/");
export default analyticsRust;
