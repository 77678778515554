import { useEffect, useState } from "preact/hooks";
const EmptyState = () => {
    const [dots, setDots] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevstate) => {
                if (prevstate.length < 3) {
                    return `${prevstate}.`;
                } else {
                    return "";
                }
            });
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{ position: "relative" }}>
            <span className={`up ripple`}>
                <h5 className="qx-widget-article-no-price">
                    <span>Valuating</span>
                    <span style={{ minWidth: "15px", display: "inline-block" }}>{dots}</span>
                </h5>
            </span>
        </div>
    );
};

export default EmptyState;
