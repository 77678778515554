import { useEffect, useRef, useState } from "preact/hooks";
import { render } from "preact";

import Emitter from "../event";
import Modal from "./Modal";
import SvgIcons from "./svgIcons";

import "./popupStyle.css";
import FireIcon from "./fire";
import Freshbadge from "./freshbadge";

const Component = ({ url, idKey, price, options }) => {
    const [articlePrice, setArticlePrice] = useState(price);
    const [priceDirection, setPriceDirection] = useState();
    const [showModal, setShowModal] = useState(false);
    const [popupLeft, setPopupLeft] = useState(0);
    const [animationDirection, setAnimationDirection] = useState("");
    const [popupTop, setPopupTop] = useState(0);
    const [rerender, setRerender] = useState(false);
    const [rippleColor, setRipple] = useState("");
    const [differencePoint, setDiff] = useState();
    const lastEntered = useRef(null);
    const elmRef = useRef(null);

    const DifferencePoint = (string1, string2) => {
        if (string1.length !== string2.length) {
            return 0;
        } else {
            for (let i = 0; i < string1.length; i++) {
                if (string1[i] !== string2[i]) {
                    return i;
                }
            }
        }
    };

    useEffect(() => {
        const handlePriceChangeWrapper = (prices) => {
            let timeout = Math.floor(Math.random() * 2000);
            setTimeout(() => handlePriceChange(prices), timeout);
        };
        const handlePriceChange = (prices) => {
            const currentPrice = articlePrice;
            const newPrice = prices[url];
            if (newPrice) {
                setDiff(DifferencePoint(currentPrice.toFixed(2), newPrice.toFixed(2)));
                if (currentPrice !== newPrice) {
                    setArticlePrice(newPrice);
                    if (currentPrice < newPrice) {
                        setPriceDirection("up");
                        setAnimationDirection("up");
                    } else {
                        setPriceDirection("down");
                        setAnimationDirection("down");
                    }
                    setTimeout(() => {
                        setAnimationDirection("");
                    }, 2000);
                }
            }
        };
        Emitter.on("prices", handlePriceChangeWrapper);
        return () => {
            Emitter.off("prices", handlePriceChangeWrapper);
        };
    }, [articlePrice]);

    //Fucntion to add class to price
    const priceClass = () => {
        let _class = "qx-widget-article-price";
        if (animationDirection === "up") {
            _class += " qx-widget-priceUp";
        } else if (animationDirection === "down") {
            _class += " qx-widget-priceDown";
        } else {
            _class = _class;
        }

        // to add class based on price direction
        if (priceDirection === "up") {
            _class += " qx-heading-priceup";
        } else if (priceDirection === "down") {
            _class += " qx-heading-pricedown";
        } else {
            _class = _class;
        }

        return _class;
    };

    const onMouseEnter = () => {
        setShowModal(true);

        // set timestamp
        lastEntered.current = new Date().getTime();
    };

    const onMouseLeave = (e) => {
        setRerender(true);
        if (
            !document.getElementById("qx-popup-container").contains(e.relatedTarget) &&
            !elmRef.current.contains(e.relatedTarget)
        ) {
            setShowModal(false);
        }
        if (!elmRef.current.contains(e.relatedTarget)) {
            const priceContainers = document.querySelectorAll("[qontento-widget]");
            for (let i = 0; i < priceContainers.length; i++) {
                if (priceContainers[i].contains(e.relatedTarget)) {
                    setRerender(false);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        const elm = elmRef.current;
        elm.addEventListener("mouseenter", onMouseEnter);
        return () => {
            elm.removeEventListener("mouseenter", onMouseEnter);
        };
    }, []);

    useEffect(() => {
        const elm = elmRef.current;
        const popupContainer = document.getElementById("qx-popup-container");

        elm.addEventListener("mouseleave", onMouseLeave);
        popupContainer.addEventListener("mouseleave", onMouseLeave);

        return () => {
            elm.removeEventListener("mouseleave", onMouseLeave);
            popupContainer.removeEventListener("mouseleave", onMouseLeave);
        };
    }, [showModal]);

    useEffect(() => {
        setTimeout(() => {
            const articlePriceH5 = elmRef.current?.querySelector("h5");

            if (articlePriceH5) {
                var viewportOffset = articlePriceH5.getBoundingClientRect();

                const screenWidth = document?.documentElement?.clientWidth;

                let offset = screenWidth - viewportOffset.x;

                if (offset < 335) {
                    if (screenWidth - 335 < viewportOffset.x) {
                        offset = 335 - offset;
                        setPopupLeft(articlePriceH5.getBoundingClientRect().left - offset);
                    }
                } else {
                    setPopupLeft(articlePriceH5.getBoundingClientRect().left - 4);
                }
            }
        }, 100);

        // logic only for nyoooz.com
        let parentDiv = document?.getElementsByTagName("h5");
        for (let i = 0; i < parentDiv.length; i++) {
            parentDiv[i].style.height = "auto";
            parentDiv[i].style.overflow = "visible";
        }
    }, [showModal]);

    // to fix spacing issue in newzbangla
    useEffect(() => {
        let elms = document.querySelectorAll(".post-item.small .post-content");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "unset";
            elms[i].style.minHeight = "70px";
        }
    }, []);

    // to fix popup clipping issue in newzbangla
    useEffect(() => {
        let elms = document.querySelectorAll(".PopularPosts");
        for (let i = 0; i < elms.length; i++) {
            let elm = elms[i];
            let lists = elm.querySelectorAll("ul");
            for (let j = 0; j < lists.length; j++) {
                lists[j].style.overflow = "visible";
            }
        }
        let elms2 = document.querySelectorAll(".img-thumb");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.overflow = "visible";
        }
    }, []);

    useEffect(() => {
        // logic to prevent price div from overlapping in nyoooz listed articles

        setTimeout(() => {
            let DivList3 = document.querySelectorAll(".post-info-2");
            for (let i = 0; i < DivList3.length; i++) {
                const elm = DivList3[i];
                // code to change margin bottom from h5
                let head = elm.querySelector("h5.qx-widget-article-price");
                if (head) {
                    head.style.marginBottom = "4px";
                }
            }
        }, 100);
    }, []);

    // logic to calculate whether to show popup on above price or below price
    useEffect(() => {
        const articlePriceH5 = elmRef.current;
        const windowHeight = window.innerHeight;
        const bottomPosition = articlePriceH5.getBoundingClientRect().bottom;

        // show popup on top if there is less than 200px space below to bottom edge of price box
        if (articlePriceH5) {
            if (windowHeight - bottomPosition < 200) {
                setPopupTop(window.scrollY + (articlePriceH5.getBoundingClientRect().top - 195));
            } else {
                setPopupTop(window.scrollY + articlePriceH5.getBoundingClientRect().bottom - 4);
            }
        }
    }, [showModal]);

    useEffect(() => {
        if (options?.popup && (showModal || rerender)) {
            render(
                <Modal
                    url={url}
                    options={options}
                    modal={showModal}
                    articlePrice={articlePrice}
                    popupLeft={popupLeft}
                    priceDirection={priceDirection}
                    popupTop={popupTop}
                />,
                document.getElementById("qx-popup-container")
            );
        }
    }, [showModal, rerender]);

    useEffect(() => {
        if (options?.popup && showModal) {
            render(
                <Modal
                    url={url}
                    options={options}
                    modal={showModal}
                    articlePrice={articlePrice}
                    popupLeft={popupLeft}
                    priceDirection={priceDirection}
                    popupTop={popupTop}
                />,
                document.getElementById("qx-popup-container")
            );
        }
    }, [articlePrice, priceDirection, url, options, popupLeft, popupTop]);

    useEffect(() => {
        setRipple(priceDirection);
    }, []);
    let _articlePrice = `${articlePrice}`.slice(0, `${articlePrice}`.indexOf(".") + 3);

    return (
        <div ref={elmRef} id={`qx-widget-${idKey}`} style={{ position: "relative" }}>
            <span className={`${rippleColor} ripple`}>
                <h5
                    className={priceClass()}
                    onClick={() => {
                        // only run if lastEntered was atleast 100ms ago
                        if (
                            !lastEntered.current ||
                            new Date().getTime() - lastEntered.current > 100
                        ) {
                            setRerender(true);
                            setShowModal(!showModal);
                        }
                    }}
                >
                    {options.currency === "inr" && "₹"}
                    {options.currency === "usd" && "$"}
                    {options.currency === "q" && (
                        <svg
                            style={{ marginRight: "3px", verticalAlign: "text-bottom" }}
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.4791 16.1741C17.2922 16.4144 17.0884 16.6457 16.8676 16.8664C16.6462 17.0878 16.4142 17.2921 16.1733 17.4794L13.897 15.2033L11.835 17.2652L13.3877 18.8179C10.9812 19.395 8.34025 18.7445 6.46208 16.8664C3.58867 13.9932 3.58867 9.33483 6.46208 6.46163C9.33548 3.58842 13.9942 3.58842 16.8676 6.46163C18.7465 8.3404 19.3968 10.9825 18.8185 13.3896L17.2647 11.8359L15.2026 13.8978L17.4791 16.1741ZM20.5396 19.2345C20.3414 19.4664 20.1325 19.6924 19.9131 19.9117C19.6931 20.1317 19.4665 20.3411 19.234 20.5398L20.6323 21.9381L18.5702 24L16.7399 22.1698C12.3959 24.2681 7.02049 23.5154 3.41655 19.9117C-1.13885 15.3567 -1.13885 7.9714 3.41655 3.41632C7.97196 -1.13877 15.3577 -1.13877 19.9131 3.41632C23.5177 7.02066 24.2701 12.397 22.1702 16.7411L23.9999 18.5707L21.9378 20.6326L20.5396 19.2345Z"
                                fill="black"
                            ></path>
                        </svg>
                    )}
                    {differencePoint ? (
                        <span>
                            {_articlePrice.substring(0, differencePoint)}
                            <span class="qx-colored-price">
                                {_articlePrice.substring(differencePoint)}
                            </span>
                        </span>
                    ) : (
                        _articlePrice
                    )}
                    <SvgIcons priceDirection={priceDirection} />
                </h5>
            </span>
        </div>
    );
};

export default Component;
