import { useState } from "preact/hooks";
import analyticsRust from "../../analyticsRust";
import DemandSection from "../DemandSection";

const Accuracy = ({ url, price, options }) => {
    const [accuracyNum, setAccuracyNum] = useState(Math.random() * (29 - 20) + 20);

    return (
        <div className="qx-widget-demand-accuracy">
            <DemandSection url={url} price={price} options={options} />
            <div
                onClick={() => {
                    analyticsRust.graphEvent({
                        articleUrl: url,
                        subtype: "click",
                        price,
                        element: "accuracy",
                    });
                }}
                className="qx-widget-accuracy-num"
            >
                Accuracy <b>{accuracyNum.toFixed(2)}%</b>
            </div>
        </div>
    );
};

export default Accuracy;
