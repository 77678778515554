import psl from "psl";

const Injector = {
    inject: (env) => {
        const method = Injector[env];
        if (!method) throw new Error("Invalid method passed");
        method();
    },
    default: () => {},

    checkDomain: (link) => {
        const linkHost = new URL(link).hostname;
        const parsed1 = psl.parse(linkHost);
        const parsed2 = psl.parse(window.location.hostname);
        return parsed1.sld === parsed2.sld;
    },

    createQontentoWidget: (link, heading) => {
        let qontentoDiv = document.createElement("div");
        const qontentoWidget = document.createAttribute("qontento-widget");
        let dataUrl = document.createAttribute("data-url");
        dataUrl.value = link;
        qontentoDiv.setAttributeNode(qontentoWidget);
        qontentoDiv.setAttributeNode(dataUrl);
        qontentoDiv.classList.add("qx-heading");
        if (heading) {
            qontentoDiv.classList.add("qx-article");
        }
        // if (Math.random() > 0.3333) {
        //     if (Math.random() > 0.5) {
        //         qontentoDiv.classList.add("version1");
        //     } else {
        //         qontentoDiv.classList.add("version4");
        //     }
        // } else {
        qontentoDiv.classList.add("version5");
        // }

        return qontentoDiv;
    },

    TargetLinkAppendToParent: (query) => {
        const elms = document.querySelectorAll(query);
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            let articleUrl = elm.href;

            if (elm.parentNode.querySelector("[qontento-widget]") || !articleUrl) {
                continue;
            }

            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }
            const qontentoDiv = Injector.createQontentoWidget(articleUrl);

            elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
        }
    },

    TargetContainerAppendToParent: (query) => {
        const elms = document.querySelectorAll(query);
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.querySelector("a");
            if (linkelm && linkelm.href) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
    },

    TargetLinkChildAppendToParent: (query, level) => {
        const elms = document.querySelectorAll(query);
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkelm = elm;
            for (let j = 0; j < level; j++) {
                linkelm = linkelm.parentNode;
            }
            if (
                linkelm &&
                linkelm.nodeName &&
                linkelm.nodeName.toLowerCase() === "a" &&
                linkelm.href
            ) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
    },

    ShowOverImage: (query, level, parentLevel) => {
        const elms = document.querySelectorAll(query);
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            let parent = elm;
            for (let j = 0; j < parentLevel; j++) {
                parent = parent.parentNode;
            }

            if (parent.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkelm = elm;
            for (let j = 0; j < level; j++) {
                linkelm = linkelm.parentNode;
            }
            if (linkelm && linkelm.nodeName && linkelm.nodeName.toLowerCase() === "a") {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });

                qontentoDiv.style.setProperty("position", "absolute", "important");
                // qontentoDiv.style.textAlign = "right";
                qontentoDiv.style.zIndex = 10;
                qontentoDiv.style.bottom = 0;
                qontentoDiv.style.left = 0;

                parent.style.position = "relative";

                parent.append(qontentoDiv);
            }
        }
    },

    TargetHeadlineFloatRightInline: (query, level) => {
        const elms = document.querySelectorAll(query);
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkelm = elm;
            for (let j = 0; j < level; j++) {
                linkelm = linkelm.parentNode;
            }
            if (
                linkelm &&
                linkelm.nodeName &&
                linkelm.nodeName.toLowerCase() === "a" &&
                linkelm.href
            ) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                qontentoDiv.classList.add("inline", "float-right");
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
    },

    wordpress: () => {
        const elms = document.getElementsByClassName("entry-title td-module-title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }

            const articleUrl = elm.children[0].href;

            if (articleUrl) {
                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.appendChild(qontentoDiv);
            }
        }

        // to add price to heading
        let heading = document.querySelector("h1.entry-title");

        if (!heading) {
            heading = document.querySelector("h1.tdb-title-text");
        }

        if (
            heading &&
            heading.parentNode.querySelectorAll("[qontento-widget]").length === 0 &&
            !heading.classList.contains("td-page-title")
        ) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        // to fix index issues
        const containers = document.querySelectorAll(".td_module_mx1 .td-module-meta-info");
        for (let i = 0; i < containers.length; i++) {
            containers[i].style.zIndex = 3;
        }

        // to fix header overlapping issue

        const headerWrap1 = document.querySelector(".td-header-wrap");
        if (headerWrap1 && parseInt(getComputedStyle(headerWrap1).zIndex) > 999) {
            headerWrap1.style.zIndex = 999;
        }

        const headerWrap2 = document.querySelector(".td-header-menu-wrap-full");
        if (headerWrap2 && parseInt(getComputedStyle(headerWrap2).zIndex) > 999) {
            headerWrap2.style.zIndex = 999;
        }
    },
    nyoooz: () => {
        // logic to fix popup overlap issue in automobile and technology section in nyoooz.com
        const DivList = document?.getElementsByClassName("theiaStickySidebar");
        for (let i = 0; i < DivList.length; i++) {
            DivList[i].style.zIndex = 1;
        }
        // logic to make price visible in auto, travel, lifestyle etc section of nyoooz
        let DivList2 = document.querySelectorAll(".post-info");
        for (let i = 0; i < DivList2.length; i++) {
            const elm = DivList2[i];
            // code to remove set height from h3
            let head = elm.querySelector("h3");
            if (head) {
                head.style.height = "unset";
                head.style.minHeight = "54px";
                head.style.overflow = "visible";
            }
        }

        // logic to add prices
        const elms = document.getElementsByClassName("text-clipping");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }

            // code for nyoooz.com
            let articleUrl = elm.href;
            // for politics page
            if (!articleUrl) {
                articleUrl = elm?.querySelector("a")?.href;
            }
            // to add widget
            if (articleUrl) {
                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);

                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        // add price related articles
        Injector.TargetContainerAppendToParent(".grid-item > h5");

        // logic to add article price on article page
        let headingContainer = document.querySelector(".post-header");
        if (headingContainer) {
            let heading = headingContainer.querySelector("h1");
            if (heading) {
                const articleUrl = window.location.href;

                let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);

                heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
            }
        }

        // Backup if text-clipping class is not present like in some pages of hindi nyoooz
        Injector.TargetLinkAppendToParent(".post-text a");

        Injector.TargetLinkAppendToParent(".post-info>h3>a");

        Injector.TargetLinkAppendToParent(".post-info-2>h5>a");
    },
    timesofbengal: () => {
        const elms = document.getElementsByClassName("article-title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            const articleUrl = elm.children[0].href;

            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }

            let qontentoDiv = document.createElement("div");
            const qontentoWidget = document.createAttribute("qontento-widget");
            let dataUrl = document.createAttribute("data-url");
            dataUrl.value = articleUrl;
            qontentoDiv.setAttributeNode(qontentoWidget);
            qontentoDiv.setAttributeNode(dataUrl);
            elm.appendChild(qontentoDiv);
        }
    },
    gadgets: () => {
        const elms = document.getElementsByClassName("gXWlF ");

        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            const articleUrl = elm.children[0].href;

            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }

            let singleUrl = articleUrl.split("/");
            if (singleUrl[2] === "www.gadgetsnow.com") {
                let qontentoDiv = document.createElement("div");
                const qontentoWidget = document.createAttribute("qontento-widget");
                let dataUrl = document.createAttribute("data-url");
                dataUrl.value = articleUrl;
                qontentoDiv.setAttributeNode(qontentoWidget);
                qontentoDiv.setAttributeNode(dataUrl);
                elm.appendChild(qontentoDiv);
            }
        }
    },

    // injection logic for newzbangla
    newzbangla: () => {
        const elms = document.getElementsByClassName("post-content");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }

            let linkElm = elm.querySelector("a");
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }

        // For articles with bg image
        const elms2 = document.getElementsByClassName("fbt-title-caption");
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            // to skip some articles which are covered in fifth type of articles
            if (elm.classList.contains("d-table")) {
                continue;
            }

            let linkElm = elm.querySelectorAll("a")[1];
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }

        //For third type of articles
        const elms3 = document.getElementsByClassName("post-title");
        for (let i = 0; i < elms3.length; i++) {
            const elm = elms3[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkElm = elm.querySelector("a");
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }
        //For fourth type of articles
        const elms4 = document.getElementsByClassName("item-title");
        for (let i = 0; i < elms4.length; i++) {
            const elm = elms4[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkElm = elm.querySelector("a");
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }
        // For fifth type of articles
        const elms5 = document.getElementsByClassName("d-table-cell");
        for (let i = 0; i < elms5.length; i++) {
            const elm = elms5[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkElm = elm.querySelector("a");
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }
        // For sixth type of articles
        const elms6 = document.getElementsByClassName("post-item");
        for (let i = 0; i < elms6.length; i++) {
            const elm = elms6[i];

            // logic to check if qontento container div already exists
            if (elm.querySelector("[qontento-widget]") || elm.querySelector(".d-table-cell")) {
                continue;
            }
            let linkElm = elm.querySelectorAll("a")[1];
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.insertBefore(qontentoDiv, linkElm.nextSibling);
            }
        }
    },
    enewsroom: () => {
        Injector.wordpress();
    },
    chandigarhcitynews: () => {
        Injector.wordpress();
    },
    kreedon: () => {
        // logic to fix headline overlapping issue
        const DivList = document?.querySelectorAll(".td_module_flex_3 .td-module-meta-info");
        for (let i = 0; i < DivList.length; i++) {
            DivList[i].style.zIndex = "unset";
        }

        // logic to fix overlapping issue in footer
        const footerBlockElement = document?.querySelector(".td-footer-wrapper .td_block_wrap");
        if (footerBlockElement) {
            footerBlockElement.style.transform = "none";
        }

        Injector.wordpress();
    },
    trendrays: () => {
        Injector.titleClass();

        const heading = document.querySelector("h1.title");
        if (heading) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);

            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    businessnewsthisweek: () => {
        Injector.TargetContainerAppendToParent(".mh-custom-posts-small-title");

        Injector.TargetContainerAppendToParent(".entry-title.mh-posts-list-title");

        Injector.TargetContainerAppendToParent(".mh-custom-posts-xl-title");

        // to fix popup issues
        const containerelms = document.querySelectorAll(".mh-custom-posts-header");
        for (let i = 0; i < containerelms.length; i++) {
            const elm = containerelms[i];
            elm.style.overflow = "visible";
        }
        const containerelms2 = document.querySelectorAll(".mh-widget");
        for (let i = 0; i < containerelms2.length; i++) {
            const elm = containerelms2[i];
            elm.style.overflow = "visible";
        }
        const containerelms3 = document.querySelectorAll(".mh-content");
        for (let i = 0; i < containerelms3.length; i++) {
            const elm = containerelms3[i];
            elm.style.overflow = "visible";
        }
    },
    techquila: () => {
        // logic to fix headline overlapping issue
        const DivList = document?.getElementsByClassName("td-module-meta-info");
        for (let i = 0; i < DivList.length; i++) {
            DivList[i].style.zIndex = "unset";
        }

        // fix wrapper issue
        const Wrapper = document?.querySelector(".td-main-content-wrap .tdc_zone");
        if (Wrapper) {
            Wrapper.style.zIndex = "unset";
        }

        // fix block issue
        const BlockElement = document?.querySelector(
            ".td_block_inner.tdb-block-inner.td-fix-index"
        );
        if (BlockElement) {
            BlockElement.style.transform = "none";
        }

        Injector.wordpress();
    },
    fossbytes: () => {
        //for simple articles
        Injector.TargetContainerAppendToParent(".elementor-post__title");

        // for headlines
        const headlineElements = document.querySelectorAll(
            ".elementor-heading-title.elementor-size-large"
        );
        for (let i = 0; i < headlineElements.length; i++) {
            const elm = headlineElements[i];
            const linkElm = elm.querySelector("a");
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                // to prevent redirection when we click on price
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });

                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        const heading = document.querySelector("h1.elementor-heading-title.elementor-size-default");
        if (heading) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        // to fix clipping issue in cards
        const cardContainers = document?.querySelectorAll(".elementor-posts .elementor-post__card");
        for (let i = 0; i < cardContainers.length; i++) {
            cardContainers[i].style.overflow = "unset";
        }

        const articleContainers = document?.querySelectorAll(
            ".elementor-posts--skin-classic .elementor-post"
        );
        for (let i = 0; i < articleContainers.length; i++) {
            articleContainers[i].style.overflow = "unset";
        }
    },
    popdiaries: () => {
        Injector.entryTitleClass();

        Injector.TargetContainerAppendToParent("h3.title");

        const heading = document.querySelector("h1.entry-title.s-post-title.bb-mb-el");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    gurgaontimes: () => {
        // fix wrapper issue
        const Wrapper = document?.querySelector(".td-main-content-wrap .tdc_zone");
        if (Wrapper) {
            Wrapper.style.zIndex = "unset";
        }

        const wrapper2 = document?.querySelector(".tdi_170 > .wpb_wrapper");
        if (wrapper2) {
            wrapper2.style.zIndex = 10;
        }

        const wrapper3 = document?.querySelector(".tdi_110 > .wpb_wrapper");
        if (wrapper3) {
            wrapper3.style.zIndex = 10;
        }

        Injector.wordpress();
    },
    moviegabbit: () => {
        Injector.entryTitleClass();

        const elms2 = document.querySelectorAll(
            ".wp-block-latest-posts__list.wp-block-latest-posts > li"
        );
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];
            const linkelm = elm.querySelectorAll("a")[1];
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }
        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    hellomumbai: () => {
        Injector.wordpress();

        const recentArticles = document.querySelectorAll(".widget_recent_entries >ul >li");
        for (let i = 0; i < recentArticles.length; i++) {
            const elm = recentArticles[i];

            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }

            const linkelm = elm.querySelectorAll("a")[0];
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }

        const recentwrapper = document.querySelector(".widget.widget_recent_entries");
        if (recentwrapper) {
            recentwrapper.style.overflow = "unset";
        }

        const asideWidget = document.querySelector("aside.widget");
        if (asideWidget) {
            asideWidget.style.overflow = "unset";
        }
        // Injector.prnewswire("12313");
    },

    titleClass: () => {
        Injector.TargetContainerAppendToParent(".title");

        const heading = document.querySelector("h1.single-post-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
        // Injector.newsvoir();
    },
    newsedge360: () => {
        Injector.TargetContainerAppendToParent(".jeg_post_title");

        const heading = document.querySelector(".single.single-post h1.jeg_post_title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    hellowomeniya: () => {
        Injector.entryTitleClass();

        Injector.TargetLinkAppendToParent(".vce-post-link");

        Injector.TargetContainerAppendToParent(".vce-featured-title");

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        // logic to prevent price box from going out of the article div
        setTimeout(() => {
            const postContainers = document.querySelectorAll(".vce-post");
            for (let i = 0; i < postContainers.length; i++) {
                postContainers[i].style.height = "initial";
            }

            const mainBoxes = document.querySelectorAll(".main-box-half");

            for (let i = 0; i < mainBoxes.length; i++) {
                mainBoxes[i].style.height = "689px";
            }

            const loopWraps = document.querySelectorAll(".main-box-half .vce-loop-wrap");

            for (let i = 0; i < loopWraps.length; i++) {
                loopWraps[i].style.height = "608px";
            }
        }, 1500);
    },

    helloindia: () => {
        Injector.TargetContainerAppendToParent(".post-box-title");

        Injector.TargetContainerAppendToParent(".tabs-wrap > ul > li >h3");

        Injector.TargetContainerAppendToParent(".featured-title > h2");

        const footerContainers = document.querySelectorAll(".posts-list");
        for (let i = 0; i < footerContainers.length; i++) {
            const footerContainer = footerContainers[i];
            const elms4 = footerContainer.querySelectorAll("ul > li >h3");
            for (let j = 0; j < elms4.length; j++) {
                const elm = elms4[j];
                const linkelm = elm.querySelector("a");
                if (linkelm) {
                    const articleUrl = linkelm.href;

                    if (!Injector.checkDomain(articleUrl)) {
                        continue;
                    }

                    let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                    elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
                }
            }
        }

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        // to fix hidden price issues
        setTimeout(() => {
            const wideBoxes = document.querySelectorAll(".wide-box li");
            for (let i = 0; i < wideBoxes.length; i++) {
                wideBoxes[i].style.height = "initial";
                wideBoxes[i].style.minHeight = "200px";
            }
            const slider = document.querySelector("#slideshow38");
            if (slider) {
                slider.style.height = "280px";
            }
        }, 1000);
    },

    techoutlook: () => {
        Injector.titleClass();
        const excerpt = document.querySelector(".post-tp-1-header .single-post-excerpt");
        if (excerpt) {
            excerpt.style.marginTop = "unset";
        }
    },
    entryTitleClass: () => {
        Injector.TargetContainerAppendToParent(".entry-title");
    },
    garhwalitraveller: () => {
        Injector.TargetContainerAppendToParent(".entry-title");
        Injector.TargetLinkAppendToParent("a.wp-block-latest-posts__post-title");

        const heading = document.querySelector(
            ".single.single-post header.page-header>h2.page-title"
        );
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    thekataam: () => {
        Injector.entryTitleClass();

        const recentPosts = document.querySelectorAll("nav[aria-label='Recent Posts'] li");
        for (let i = 0; i < recentPosts.length; i++) {
            const elm = recentPosts[i];
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.querySelector("a");
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    bulbulonthewing: () => {
        Injector.postTitleClass();

        const heading = document.querySelector(".post-title.single-title.entry-title h1");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    postTitleClass: () => {
        Injector.TargetContainerAppendToParent(".post-title");
    },

    salahuddinayyubi: () => {
        Injector.postTitleClass();

        const heading = document.querySelector(".single.single-post h1.post-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    xtratime: () => {
        Injector.entryTitleClass();

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    jaianndata: () => {
        Injector.postTitleClass();

        const heading = document.querySelector(".single-post h2.post-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const listelms = document.querySelectorAll(".list-post-block .list-post > li");
        for (let i = 0; i < listelms.length; i++) {
            listelms[i].style.maxHeight = "initial";
        }
    },

    newspatrolling: () => {
        Injector.TargetContainerAppendToParent(".post-box-title");

        Injector.TargetContainerAppendToParent("article.item-list h2");

        Injector.TargetContainerAppendToParent(".related-item h3");

        const heading = document.querySelector("h1.name.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const recentItems = document.querySelectorAll(".recent-box .recent-item");

        for (let i = 0; i < recentItems.length; i++) {
            recentItems[i].style.height = "270px";
        }

        const relatedItems = document.querySelectorAll("#related_posts .related-item");

        for (let i = 0; i < relatedItems.length; i++) {
            relatedItems[i].style.height = "270px";
        }
    },
    jantaserishta: () => {
        Injector.TargetContainerAppendToParent(".text_heading");

        Injector.TargetContainerAppendToParent("h2.s-amp-h3");

        Injector.TargetContainerAppendToParent("h1.overlay_text");

        const heading = document.querySelector("h1.trending_heading");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        // styling fixes on the page
        const dates1 = document.querySelectorAll(".box_bottom_text");
        for (let i = 0; i < dates1.length; i++) {
            dates1[i].style.position = "initial";
        }

        const dates2 = document.querySelectorAll(".pos_absolute.bottom_7");
        for (let i = 0; i < dates2.length; i++) {
            dates2[i].style.position = "initial";
        }
    },
    hindiwebnews24: () => {
        const elms1 = document.querySelectorAll(".slider-post-title");
        for (let i = 0; i < elms1.length; i++) {
            const linkelm = elms1[i];
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                linkelm.querySelector(".heading")?.append(qontentoDiv);
            }
        }
        const elms2 = document.querySelectorAll("a.post-title");
        for (let i = 0; i < elms2.length; i++) {
            const linkelm = elms2[i];
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                if (
                    document.querySelector(".recentposts").contains(linkelm) ||
                    linkelm.parentNode.classList.contains("big-post")
                ) {
                    qontentoDiv.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    linkelm.append(qontentoDiv);
                } else {
                    linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
                }
            }
        }
        Injector.TargetLinkAppendToParent(".grid_post-title");

        Injector.TargetContainerAppendToParent(".item-title");

        Injector.TargetContainerAppendToParent("h2.post-title.entry-title");

        const elms6 = document.querySelectorAll("h3.mega_post-title");
        for (let i = 0; i < elms6.length; i++) {
            const elm = elms6[i];
            const linkelm = elm.parentNode;
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }

        const elms7 = document.querySelectorAll(".random_post-title");
        for (let i = 0; i < elms7.length; i++) {
            const elm = elms7[i];
            const linkelm = elm.parentNode;
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }

        const heading = document.querySelector(".post h2.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    postTitle: () => {
        Injector.postTitleClass();

        const heading = document.querySelector("h1.post-title");
        if (
            heading &&
            heading.parentNode.querySelectorAll("[qontento-widget]").length === 0 &&
            document.querySelectorAll("body.static_page").length === 0
        ) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
        const elms = document.querySelectorAll("#main-menu ul > li > ul");
        for (let i = 0; i < elms.length; i++) {
            let elm = elms[i];
            if (parseInt(getComputedStyle(elm).zIndex) > 999) {
                elm.style.zIndex = 999;
            }
        }
    },

    timesofmalwahindi: () => {
        const elms1 = document.querySelectorAll(".img-credits a h3");
        for (let i = 0; i < elms1.length; i++) {
            const elm = elms1[i];
            const linkelm = elm.parentNode;
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }

        const elms2 = document.querySelectorAll(".img-credits>h3");
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];
            const linkelm = elm.parentNode.previousSibling;
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        Injector.TargetLinkAppendToParent(".post-content a");

        const elms4 = document.querySelectorAll(".item-title");
        for (let i = 0; i < elms4.length; i++) {
            const elm = elms4[i];
            const linkelm = elm.querySelector("a");
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.appendChild(qontentoDiv);
            }
        }

        const elms5 = document.querySelectorAll(".featured-widget-list li a>h5");
        for (let i = 0; i < elms5.length; i++) {
            const elm = elms5[i];
            const linkelm = elm.parentNode;
            if (linkelm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.style.marginTop = "24px";
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                elm.append(qontentoDiv);
            }
        }

        Injector.TargetContainerAppendToParent(".FeaturedPost h3");

        const heading = document.querySelector("h1.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const imageThumbs = document.querySelectorAll(".img-thumb");
        for (let i = 0; i < imageThumbs.length; i++) {
            imageThumbs[i].parentNode.style.overflow = "hidden";
        }
    },

    punefoodblog: () => {
        Injector.TargetContainerAppendToParent(".elementor-post__title");

        Injector.TargetContainerAppendToParent(".elementor-heading-title");

        const heading = document.querySelector("h1.elementor-heading-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    outsetbooks: () => {
        Injector.postTitleClass();

        const heading = document.querySelector(".post-view h3.entry-title.post-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    inanews: () => {
        Injector.postTitleClass();

        Injector.TargetContainerAppendToParent(".rcp-title");

        Injector.TargetContainerAppendToParent(".mega-title");

        Injector.TargetContainerAppendToParent(".related-content h3");

        const heading = document.querySelector("h1.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    vcworld: () => {
        Injector.TargetContainerAppendToParent("h2.posttitle");

        Injector.TargetContainerAppendToParent("li.post>h4");

        Injector.TargetLinkAppendToParent("ul.headlines>li>a");

        Injector.TargetLinkAppendToParent("#related-posts>ul>li>a");

        const heading = document.querySelector("h1.posttitle");
        if (heading && heading.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.append(qontentoDiv);
        }
    },

    newsindialive: () => {
        Injector.TargetContainerAppendToParent(".post-box-title");
        Injector.TargetContainerAppendToParent(".related-item h3");

        const heading = document.querySelector("h1.name.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    atulyaloktantranews: () => {
        Injector.TargetContainerAppendToParent("h2.gb-headline");

        const elms2 = document.querySelectorAll(".crp_title");
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];
            const linkelm = elm.parentNode;
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");

        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");
        Injector.TargetContainerAppendToParent(".uael-post__title");

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    suspensecrime: () => {
        const elms = document.querySelectorAll(".itemTitle");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            // logic to check for colombiaonesuccess
            const colombiaContainers = document.querySelectorAll(
                ".lhs[data-lazyload=true][template_id]"
            );
            let inColombia = false;
            for (let i = 0; i < colombiaContainers.length; i++) {
                if (colombiaContainers[i].contains(elm)) {
                    inColombia = true;
                    break;
                }
            }
            if (inColombia) {
                continue;
            }

            let linkelm = elm.parentNode;
            if (linkelm.nodeName && linkelm.nodeName.toLowerCase() !== "a") {
                linkelm = elm.parentNode.parentNode;
            }
            if (linkelm.nodeName && linkelm.nodeName.toLowerCase() !== "a") {
                linkelm = elm.querySelector("a");
            }
            if (
                elm.parentNode.querySelector("[qontento-widget]") ||
                getComputedStyle(elm).backgroundImage !== "none" ||
                (elm.nextElementSibling &&
                    getComputedStyle(elm.nextElementSibling).backgroundImage !== "none")
            ) {
                continue;
            }

            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                qontentoDiv.addEventListener("click", (e) => {
                    e.stopPropagation();
                });
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
        const heading = document.querySelector("h1.story-heading");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const sectionHeadings = document.querySelectorAll(".feed-grid");
        for (let i = 0; i < sectionHeadings.length; i++) {
            sectionHeadings[i].style.marginTop = "2.5rem";
        }
    },
    thepinkvelvetblog: () => {
        Injector.entryTitleClass();

        Injector.TargetLinkAppendToParent("h2.wp-block-kadence-advancedheading>a");

        Injector.TargetLinkAppendToParent("h4.wp-block-kadence-advancedheading>a");

        Injector.TargetContainerAppendToParent("h2.has-text-align-center");

        const elms4 = document.querySelectorAll("figure.wp-block-kadence-image");
        for (let i = 0; i < elms4.length; i++) {
            const elm = elms4[i];
            const linkelm = elm.querySelector("a");
            const headline = elm.nextElementSibling;
            if (
                !headline ||
                headline.querySelector("[qontento-widget]") ||
                !headline.classList.contains("wp-block-kadence-advancedheading")
            ) {
                continue;
            }

            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                headline.appendChild(qontentoDiv);
            }
        }

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    entryTitleAndRecent: () => {
        Injector.entryTitleClass();

        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    livehyd: () => {
        Injector.titleClass();

        Injector.TargetContainerAppendToParent(".title_small_post");

        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");

        const heading = document.querySelector("h1.title.single");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    hydtravel: () => {
        Injector.entryTitleAndRecent();
        const elms = document.querySelectorAll(".post .image-holder .text");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            const linkelm = elm.parentNode.querySelector("a");
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.appendChild(qontentoDiv);
            }
        }
    },
    timesofhyd: () => {
        Injector.livehyd();

        const elms2 = document.querySelectorAll(
            ".wp-block-latest-posts__list.wp-block-latest-posts > li"
        );
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];
            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.querySelector("a");
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                linkelm.parentNode.insertBefore(qontentoDiv, linkelm.nextSibling);
            }
        }
    },
    upkiran: () => {
        Injector.TargetContainerAppendToParent(".post-title");

        Injector.TargetLinkAppendToParent("a.wp-block-latest-posts__post-title");

        const heading = document.querySelector(".single.single-post h1.page-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    jayashankarrakhi: () => {
        Injector.entryTitleAndRecent();

        Injector.TargetContainerAppendToParent(".wp-block-post-title");

        Injector.TargetLinkAppendToParent(".widget_recent_entries ul>li>a");
    },
    worldofcharms: () => {
        const elms = document.querySelectorAll("h1.entry-title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];

            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }

            const linkelm = elm.querySelector("a");
            if (linkelm) {
                const articleUrl = linkelm.href;
                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.append(qontentoDiv);
            } else {
                const articleUrl = window.location.href;

                let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
                elm.append(qontentoDiv);
            }
        }

        // remove clipping from featured
        const featured = document.querySelectorAll(".featured-content .hentry");
        for (let i = 0; i < featured.length; i++) {
            featured[i].style.overflow = "unset";
        }
    },

    ibgnews: () => {
        Injector.TargetLinkAppendToParent("li > a.rsswidget");

        const widgetContainers = document.querySelectorAll(".widget");
        for (let i = 0; i < widgetContainers.length; i++) {
            widgetContainers[i].style.overflow = "unset";
        }

        const outerWrapper = document.getElementById("td-outer-wrap");

        if (outerWrapper) {
            outerWrapper.style.overflow = "unset";
        }

        Injector.wordpress();
        // Injector.prnewswire("19585");
        // Injector.newsvoir();
        // Injector.businesswire();
    },
    realtimes: () => {
        Injector.salahuddinayyubi();

        Injector.TargetContainerAppendToParent(".thumb-title");
        Injector.TargetLinkAppendToParent(".widget_recent_entries ul>li>a");
        Injector.TargetLinkAppendToParent("a.post-title");
    },

    jansandeshonline: () => {
        const imgs = document.querySelectorAll("span>img");
        for (let i = 0; i < imgs.length; i++) {
            if (
                imgs[i].parentNode.style.height === "" &&
                getComputedStyle(imgs[i].parentNode).height === "auto"
            ) {
                imgs[i].parentNode.style.display = "block";
                imgs[i].parentNode.style.height = getComputedStyle(imgs[i]).height;
            }
        }

        const imgs2 = document.querySelectorAll(
            ".container>.row>.col-sm-12>.border-bottom-gray>.lhs.colombiaonesuccess>div>div>div>a>span>img"
        );
        for (let i = 1; i < imgs2.length; i++) {
            imgs2[i].parentNode.parentNode.style.display = "flex";
            imgs2[i].style.maxWidth = "initial";
        }

        Injector.ShowOverImage("a>span>img", 2, 1);
        Injector.TargetLinkChildAppendToParent(".native-items a>div>h2", 2);

        const heading = document.querySelector("h1.story-heading");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    risenshine: () => {
        Injector.TargetContainerAppendToParent(".read-title");
        const heading = document.querySelector(`.single.single-post h1.entry-title`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(
            ".af-main-banner-tabbed-posts .af-double-column.list-style .read-details.pad"
        );
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.maxHeight = "initial";
        }

        const elms2 = document.querySelectorAll(".af-double-column.list-style .read-details");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.maxHeight = "initial";
        }
    },

    newsexpress24: () => {
        Injector.TargetContainerAppendToParent(".jeg_post_title");
        Injector.TargetContainerAppendToParent(".elementor-post__title");

        const elms = document.querySelectorAll(".anwp-pg-post-teaser__title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            let linkElm = elm.querySelector("a.anwp-link-without-effects");
            if (!linkElm) {
                linkElm = elm.parentNode.parentNode.querySelector("a.anwp-link-without-effects");
            }
            if (linkElm) {
                const articleUrl = linkElm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        const heading = document.querySelector("h1.elementor-heading-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms2 = document.querySelectorAll(".jeg_heroblock .jeg_post_title");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.marginBottom = "25px";
        }
    },

    blockchainmagazine: () => {
        Injector.TargetLinkAppendToParent("a.wtc.stc_h, a.mtc.stc_h");
        Injector.TargetLinkAppendToParent(".stm_posts_carousel_single .content>a.wtc");

        const elms = document.querySelectorAll(".wpb_wrapper>ul>li>a");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            let articleUrl = elm.href;

            if (elm.querySelector("[qontento-widget]")) {
                continue;
            }
            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }
            const qontentoDiv = Injector.createQontentoWidget(articleUrl);
            qontentoDiv.addEventListener("click", (e) => {
                e.stopPropagation();
            });
            elm.append(qontentoDiv);
        }

        const heading = document.querySelector("h1.stm_post_view__title");
        if (heading && heading.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.append(qontentoDiv);
        }

        const elms2 = document.querySelectorAll(
            ".stm_posts_carousel .stm_posts_carousel_single__list li h5"
        );
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.maxHeight = "120px";
        }

        const elms3 = document.querySelectorAll(".vc_tta-panel-body");
        for (let i = 0; i < elms3.length; i++) {
            elms3[i].style.setProperty("max-height", "initial", "important");
        }
    },
    techgami: () => {
        Injector.TargetContainerAppendToParent(".post_title");
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");

        const heading = document.querySelector(".the_title h1");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    moviespie: () => {
        Injector.entryTitleAndRecent();
        Injector.TargetContainerAppendToParent("figcaption.wp-caption-text");
    },
    thetinaedit: () => {
        Injector.entryTitleClass();
        const heading = document.querySelector("single.single-post h1.page-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    padhaikare: () => {
        Injector.xtratime();
        Injector.TargetLinkAppendToParent("a.wp-block-latest-posts__post-title");
    },
    prnewswire: (id) => {
        Injector.TargetLinkAppendToParent(`.w${id}_title`);
        const heading = document.querySelector(`#w${id}_landing h1`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    newsvoir: () => {
        Injector.TargetLinkAppendToParent(".NewsRelDecs>h2>a");
        const heading = document.querySelector(`.NewsRelDecs_detail>h1`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    businesswire: () => {
        Injector.TargetLinkAppendToParent("#div_BWINews_indiaXDR5TR>.col-lg-12>.col-lg-9>p>a");
        const heading = document.querySelector(`article.bw-release-main>div>h1.epi-fontLg`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    newznew: () => {
        Injector.wordpress();
        Injector.prnewswire("21532");
        Injector.newsvoir();
        Injector.businesswire();
    },
    theindiabizz: () => {
        Injector.techoutlook();
        Injector.businesswire();
    },
    newsblare: () => {
        const duplicates = document.querySelectorAll(".swiper-slide-duplicate [qontento-widget]");
        for (let i = 0; i < duplicates.length; i++) {
            let childId = duplicates[i]?.children[0]?.id;
            if (document.querySelectorAll(`#${childId}`).length > 1) {
                duplicates[i].remove();
            }
        }
        Injector.xtratime();
    },
    rashtrakundalininews: () => {
        Injector.TargetContainerAppendToParent(".slide-title");
        Injector.TargetContainerAppendToParent(".feature-main-title");
        Injector.TargetContainerAppendToParent(".post-main-desc>h3");
        Injector.TargetContainerAppendToParent("p.post-desc");
        Injector.TargetContainerAppendToParent(".entry-title");
        Injector.TargetContainerAppendToParent(".featured-post-sidebar>.post-desc>h3");
        Injector.TargetLinkAppendToParent(".related-post>ul>li>a");

        const elms = document.querySelectorAll(".beside-post>a h3.post-title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.parentNode.parentNode;
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        const heading = document.querySelector(`.single.single-post h1.entry-title`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    reportertodaynews: () => {
        Injector.TargetContainerAppendToParent(".read-title>h4");
        Injector.TargetLinkAppendToParent("a.wp-block-latest-posts__post-title");
        const heading = document.querySelector(`.single.single-post h1.entry-title`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    ankitamahajan: () => {
        Injector.xtratime();
        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");
    },
    techtimes: () => {
        Injector.wordpress();
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");
    },
    mallsmarket: () => {
        Injector.TargetLinkAppendToParent(".views-field-title a");
        const heading = document.querySelector(`.page-node h1.title`);
        if (heading && heading.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.append(qontentoDiv);
        }
    },
    sandhyakal: () => {
        Injector.postTitleClass();
        const heading = document.querySelector(`.single-post h2.post-title`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    adityabharat: () => {
        Injector.TargetLinkAppendToParent("h3>a");
        Injector.TargetLinkAppendToParent("h4>a");
        Injector.TargetLinkAppendToParent("h1.title1>a");

        const heading = document.querySelector(`.single-post h2.post-title`);
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    afflospark: () => {
        Injector.TargetContainerAppendToParent(".aff-post-title-alt");
        Injector.TargetContainerAppendToParent(".aff-post-title");
        Injector.TargetLinkAppendToParent(".relatedposts>ul>li>a");

        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    glowaglitter: () => {
        const duplicates = document.querySelectorAll(".cloned [qontento-widget]");
        for (let i = 0; i < duplicates.length; i++) {
            let childId = duplicates[i]?.children[0]?.id;
            if (document.querySelectorAll(`#${childId}`).length > 1) {
                duplicates[i].remove();
            }
        }

        Injector.TargetContainerAppendToParent(".the_title");
        Injector.TargetContainerAppendToParent(".title");
        Injector.TargetContainerAppendToParent(".rp_title>h4");

        let heading = document.querySelector(".single.single-post .post_title>h2");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    charchitnews: () => {
        Injector.wordpress();
        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");
        Injector.TargetLinkAppendToParent(".tdb-next-post>a");
    },
    collegemarker: () => {
        Injector.xtratime();
        Injector.TargetContainerAppendToParent(
            ".vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_title"
        );

        const elms = document.querySelectorAll(
            "div.vc_gitem-zone.vc_gitem-zone-a.vc_custom_1635846062336"
        );
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "145px";
        }
        const elms2 = document.querySelectorAll(
            ".vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_date p"
        );
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.marginTop = "5px";
        }
    },
    onecity: () => {
        Injector.xtratime();
        Injector.TargetContainerAppendToParent(".hmb-entry-title");
        Injector.TargetContainerAppendToParent(".hms-title");
        Injector.postTitleClass();
        Injector.TargetLinkAppendToParent("a.wpp-post-title");
    },
    hellovcard: () => {
        Injector.TargetContainerAppendToParent(".title");
        let heading = document.querySelector(".post-title>h1.title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    democracynewslive: () => {
        Injector.TargetLinkChildAppendToParent(".side-list-text>a>p.ft18", 1);

        Injector.TargetLinkChildAppendToParent("h2.stand-title", 2);
        Injector.TargetLinkChildAppendToParent(".blog-widget-text>h3.ft21", 2);
        Injector.TargetLinkChildAppendToParent(".feat-widget-text", 1);
        Injector.TargetLinkChildAppendToParent(".side-list-text", 3);
        Injector.TargetLinkAppendToParent(".blog-widget-text>a");
        Injector.TargetLinkChildAppendToParent(".row-widget-text>p", 2);
        Injector.TargetLinkChildAppendToParent(".row-widget-text p", 1);

        let heading = document.querySelector("h1.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(".feat-top2-right-text");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "initial";
        }

        const elms2 = document.querySelectorAll(".row-widget-text");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.maxHeight = "initial";
        }

        const elms3 = document.querySelectorAll("footer .side-list-in");
        for (let i = 0; i < elms3.length; i++) {
            elms3[i].style.paddingBottom = "24px";
        }
    },
    foodhyd: () => {
        Injector.TargetContainerAppendToParent(".featured-news-title");
        Injector.TargetContainerAppendToParent(".blog-news-title");
        Injector.entryTitleClass();

        const heading = document.querySelector(".single.single-post h1.page-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    bullishafnews: () => {
        Injector.TargetLinkChildAppendToParent(".du_title", 1);
        Injector.TargetLinkChildAppendToParent(".latest_title", 1);
        Injector.TargetLinkChildAppendToParent(".cat_title", 3);
        Injector.TargetContainerAppendToParent(".dur_title");
        Injector.TargetContainerAppendToParent(".rec_title");

        const elms = document.querySelectorAll(".feat_title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            const linkelm = elm.parentNode.querySelector(".button_container>a");

            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
    },
    marketingmind: () => {
        Injector.TargetContainerAppendToParent(".jw-grid-one-content>h4");
        Injector.TargetContainerAppendToParent(".jw-title");

        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    realitynews: () => {
        Injector.TargetLinkChildAppendToParent("a>div>h2.hero_div", 2);
        Injector.suspensecrime();

        const elms = document.querySelectorAll("h2>a");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            let articleUrl = elm.href;

            if (elm.parentNode.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }

            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }
            const qontentoDiv = Injector.createQontentoWidget(articleUrl);

            elm.parentNode.parentNode.insertBefore(qontentoDiv, elm.parentNode.nextSibling);
        }
    },

    worsonlinenews: () => {
        Injector.TargetContainerAppendToParent(".rcp-title");
        Injector.TargetContainerAppendToParent(".post-title");
        Injector.TargetContainerAppendToParent(".item-title");
        Injector.TargetContainerAppendToParent(".related-content>h3");

        let heading = document.querySelector(".post-head>h1.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(".grid-item");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.overflow = "hidden";
        }
    },
    fastnewsindia: () => {
        Injector.TargetContainerAppendToParent(".post-description");
        Injector.TargetContainerAppendToParent(".blog_post_style2_content>h4");
        Injector.TargetLinkChildAppendToParent(".col-lg-3.col-md-3.col-sm-3.col-xs-12 a>span", 1);
        Injector.TargetLinkChildAppendToParent(".card a>h2", 1);

        let heading = document.querySelector(
            "h3>span#ctl00_ctl00_contentslidertop_ContentPlaceHolder1_txthead"
        );
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
        const elms = document.querySelectorAll(".col-lg-8.col-md-8.col-sm-8.col-xs-8");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "initial";
            elms[i].style.maxHeight = "initial";
        }
    },
    trackcgnews: () => {
        Injector.TargetContainerAppendToParent(".elementor-post__title");
        Injector.TargetContainerAppendToParent(".jeg_post_title");

        const elms2 = document.querySelectorAll(".jeg_heroblock .jeg_post_title");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.marginBottom = "25px";
        }

        const heading = document.querySelector(".single.single-post h1.elementor-heading-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    khadijabeauty: () => {
        Injector.xtratime();
        Injector.TargetContainerAppendToParent(".elementor-post__title");
        Injector.TargetContainerAppendToParent(".box-caption>h2");
    },
    hdmovies: () => {
        Injector.entryTitleClass();

        const heading = document.querySelector(".post-inner-area>h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(".hot-posts-pro .widget-content .hot-item");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "initial";
        }
    },
    womansera: () => {
        Injector.entryTitleAndRecent();
        Injector.TargetContainerAppendToParent(".post-item-title");
    },
    janmatmarathi: () => {
        Injector.realtimes();
        Injector.TargetLinkAppendToParent("li.widget-single-post-item>a");
    },
    samacharadda: () => {
        Injector.entryTitleClass();
        const elms = document.querySelectorAll(".story-content-overlay__title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.parentNode.previousElementSibling.querySelector("a");
            if (linkelm) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
    },
    rokthoklekhaninews: () => {
        Injector.TargetLinkAppendToParent("a.post-title");
        Injector.TargetLinkAppendToParent("a.post-title-lg");
        Injector.TargetContainerAppendToParent(".post-title");
        Injector.TargetLinkChildAppendToParent("a>.post-title", 1);

        const heading = document.querySelector("h1.tag_h1.node_title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll("a>[qontento-widget]");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.marginLeft = "70px";
        }
    },
    kashmirglacier: () => {
        Injector.TargetContainerAppendToParent(".jeg_post_title");

        const heading = document.querySelector(".single.single-post h1.jeg_post_title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");

        const elms2 = document.querySelectorAll(".jeg_heroblock .jeg_post_title");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.marginBottom = "25px";
        }
    },
    mknnews: () => {
        Injector.TargetContainerAppendToParent(".article-title");
        Injector.TargetContainerAppendToParent(".elespare-post-title");

        const heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    wittyflick: () => {
        Injector.TargetContainerAppendToParent(".item-title");

        const heading = document.querySelector("body.item h1.post-title.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    krishijagran: () => {
        const elms = document.querySelectorAll("h2>a");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            let articleUrl = elm.href;

            if (
                elm.parentNode.classList.contains("cat-h") ||
                elm.parentNode.parentNode.classList.contains("cat-h") ||
                elm.parentNode.parentNode.classList.contains("rt-n-lst") ||
                elm.parentNode.parentNode.classList.contains("offset-md-3") ||
                elm.parentNode.classList.contains("c") ||
                elm.parentNode.parentNode.classList.contains("h-title") ||
                elm.parentNode.parentNode.classList.contains("t-stories") ||
                elm.parentNode.parentNode.classList.contains("container") ||
                elm.parentNode.parentNode.classList.contains("int-top")
            ) {
                continue;
            }
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }

            if (!Injector.checkDomain(articleUrl)) {
                continue;
            }
            const qontentoDiv = Injector.createQontentoWidget(articleUrl);

            elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
        }

        const elms2 = document.querySelectorAll(".news-item h2");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.webkitLineClamp = 6;
        }
        Injector.TargetLinkAppendToParent(".home-top-main-post>h1>a");
        Injector.TargetLinkAppendToParent(".main-post h1>a");
        Injector.TargetLinkChildAppendToParent(".news-info>a>h2", 1);

        const heading = document.querySelector(".story h1");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    wahh: () => {
        Injector.TargetContainerAppendToParent(".rlt-title");
        Injector.TargetContainerAppendToParent(".item-title");
        Injector.entryTitleClass();
        Injector.TargetLinkAppendToParent(".separator>a");

        const heading = document.querySelector(".post.hentry>h1.post-title.entry-title");
        if (heading && heading.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.appendChild(qontentoDiv);
        }
    },
    janchowk: () => {
        Injector.wordpress();

        const elms = document.querySelectorAll("#boxyid .td-module-meta-info");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "initial";
        }
    },
    saffronfactor: () => {
        Injector.TargetContainerAppendToParent(".post-box-title");
        Injector.TargetLinkAppendToParent(".posts-list ul>li>h3>a");
        Injector.TargetLinkAppendToParent(".slider-caption>h2>a");

        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    jharkhandstatenews: () => {
        Injector.TargetContainerAppendToParent(".list-item .title");
        Injector.TargetContainerAppendToParent(".widget-content .title");
        Injector.TargetHeadlineFloatRightInline(".widget-content ul.site_ul>li>a", 0);
        Injector.TargetLinkAppendToParent(".inner>ul.list>li>a");

        Injector.TargetContainerAppendToParent("li.media>.media-body>p.small");
        Injector.TargetContainerAppendToParent(".related-post>.post>.title");

        const liElms = document.querySelectorAll(".widget-content ul.site_ul>li");
        for (let i = 0; i < liElms.length; i++) {
            liElms[i].style.clear = "right";
        }

        // const elms = document.querySelectorAll("ul.list>li.media>.media-body");
        // for (let i = 0; i < elms.length; i++) {
        //     const elm = elms[i];
        //     if (elm.parentNode.querySelector("[qontento-widget]")) {
        //         continue;
        //     }
        //     const linkelm = elms[i].parentNode.querySelector("a");
        //     if (linkelm) {
        //         const articleUrl = linkelm.href;

        //         if (!Injector.checkDomain(articleUrl)) {
        //             continue;
        //         }

        //         let qontentoDiv = Injector.createQontentoWidget(articleUrl);
        //         elm.appendChild(qontentoDiv);
        //     }
        // }

        let heading = document.querySelector(".news-post-format .page-title>span.title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    indianewsviral: () => {
        Injector.TargetContainerAppendToParent(".slider-title");
        Injector.entryTitleClass();
        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");
        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    prajaatantra: () => {
        Injector.TargetContainerAppendToParent(".article-title");
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");
        Injector.TargetLinkChildAppendToParent("a>.rpwwt-post-title", 1);
        Injector.TargetLinkChildAppendToParent("a>.crp_title", 1);
        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    anandimail: () => {
        Injector.TargetContainerAppendToParent("h3.title");
        Injector.TargetContainerAppendToParent("h5.title");

        const elms = document.querySelectorAll(".featured-slider-item>.caption>.title");
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.parentNode.parentNode.querySelectorAll("a")[1];
            if (linkelm && linkelm.href) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }
        const elms2 = document.querySelectorAll(".box-inner>.caption>.title");
        for (let i = 0; i < elms2.length; i++) {
            const elm = elms2[i];
            if (elm.parentNode.querySelector("[qontento-widget]")) {
                continue;
            }
            const linkelm = elm.parentNode.parentNode.querySelectorAll("a")[1];
            if (linkelm && linkelm.href) {
                const articleUrl = linkelm.href;

                if (!Injector.checkDomain(articleUrl)) {
                    continue;
                }

                let qontentoDiv = Injector.createQontentoWidget(articleUrl);
                elm.parentNode.insertBefore(qontentoDiv, elm.nextSibling);
            }
        }

        const heading = document.querySelector(".post-content>h1.title");
        if (heading) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    dynamicexpress: () => {
        Injector.TargetContainerAppendToParent(".post-title");
        Injector.TargetLinkAppendToParent("a.post-title");
        Injector.TargetLinkAppendToParent("li.widget-single-post-item>a");
        Injector.TargetContainerAppendToParent(".thumb-title");
        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;

            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    dailychattisgarh: () => {
        Injector.TargetLinkAppendToParent(".post-content>a");
        let heading = document.querySelector(
            ".single_post_content .post-item>.post-content>.pt-0.pb-0>.title"
        );
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
        const elms = document.querySelectorAll(".text_limit_2");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.minHeight = "52px";
        }
    },
    e2newz: () => {
        Injector.entryTitleClass();
        Injector.TargetContainerAppendToParent(".jp-relatedposts-post-title");
        let heading = document.querySelector(".single.single-post h1.s-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    timesofmalwa: () => {
        Injector.entryTitleClass();
        Injector.TargetLinkChildAppendToParent("a>.entery-category-fly>h2.entry-title", 2);
        const heading = document.querySelector(".hentry.item-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    cityandolan: () => {
        Injector.ShowOverImage(".row.no-gutters span>a>img,.bg-full-image span>a>img", 1, 2);
        Injector.ShowOverImage(
            ".col-md-12>.lhs.colombiaonesuccess>div>div>div>div>a>img, .container-fluid>.row>.col-md-12>.lhs.colombiaonesuccess>div>div>div>span>a>img",
            1,
            2
        );
        Injector.ShowOverImage(".col-sm-6 a>span>img", 2, 1);
        Injector.TargetLinkChildAppendToParent(
            ".row>.col-lg-12>.postRow>.lhs.colombiaonesuccess>div>div>a>div>h2.itemTitle",
            2
        );
        Injector.TargetLinkChildAppendToParent(".business-news-section a>h2", 1);
        Injector.TargetLinkChildAppendToParent(
            "section.postRow>.row>.col-md-12>.lhs.colombiaonesuccess>div>div>div>div>a>h2.itemTitle.title",
            1
        );
        Injector.TargetLinkChildAppendToParent(
            ".native-items.topStories>.rhs.colombiaonesuccess>div>div>div>a>div>h2",
            2
        );

        Injector.ShowOverImage(
            ".native-items.topStories>.rhs.colombiaonesuccess>div>div>div>span>a>img",
            1,
            1
        );
        Injector.ShowOverImage(
            "section.postRow.section1>.row>.col-md-8>.col-row>.col-md-12>.lhs.colombiaonesuccess>div>div>div>span>a>img",
            1,
            2
        );
        Injector.TargetLinkAppendToParent("a.gs-title");

        Injector.TargetLinkChildAppendToParent(
            ".editor-pic-section>.container-fluid>.row>.col-lg-8>.lhs.colombiaonesuccess>div:first-child>div>div>a>div>h2.itemTitle.title",
            2
        );

        Injector.ShowOverImage(
            ".editor-pic-section>.container-fluid>.row>.col-lg-8>.lhs.colombiaonesuccess>div:not(:first-child)>div>div>a>div.thumnail_img>span>img",
            3,
            2
        );

        const heading = document.querySelector(
            ".story-wrapper>.card-box.header-box>.story-heading"
        );
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },

    cricketnmore: () => {
        Injector.TargetContainerAppendToParent(".mainhead>h1.h4");
        // Injector.TargetContainerAppendToParent(".box-row-info>h3");
        // Injector.TargetLinkAppendToParent(".card-block.gallery-block>a");
        // Injector.TargetContainerAppendToParent(".box-info>h3.h6");
        Injector.TargetLinkAppendToParent(".big-news-more>a");
        // Injector.TargetLinkAppendToParent(".sm-details>h4.h6>a");
        // Injector.TargetContainerAppendToParent(".news-content>h3.h5");
        Injector.TargetLinkAppendToParent("ul.news-list>li .news-row-title>a");
        Injector.TargetLinkChildAppendToParent(".box-pd>a>p.v-title", 1);

        // Injector.ShowOverImage(".mainimg>a>img.img-fluid", 1);
        Injector.ShowOverImage(".small-img>a>img", 1, 2);
        Injector.ShowOverImage(".card.gallery>a>img.img-fluid", 1, 1);
        Injector.ShowOverImage(".box>a>img.img-fluid", 1, 1);
        Injector.ShowOverImage(".sm-img>a>img.img-fluid", 1, 2);
        // Injector.ShowOverImage(".big-news>a>img.img-fluid", 1);
        Injector.ShowOverImage(".row-img>a>img.img-fluid", 1, 2);
        // Injector.ShowOverImage(".box-pd>a>img.img-fluid", 1, 2);

        const heading = document.querySelector(".news-details>.news-header>h1.h3");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const heading2 = document.querySelector(".article>.img-title");
        if (heading2 && heading2.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading2.parentNode.insertBefore(qontentoDiv, heading2.nextSibling);
        }

        // const elms = document.querySelectorAll(".box-row-info");
        // for (let i = 0; i < elms.length; i++) {
        //     elms[i].style.flexDirection = "row";
        // }

        // const elms1 = document.querySelectorAll("h4.h6");
        // for (let i = 0; i < elms1.length; i++) {
        //     elms1[i].style.display = "flex";
        // }

        // const elms2 = document.querySelectorAll(".card.gallery");
        // for (let i = 0; i < elms2.length; i++) {
        //     elms2[i].style.height = "305px";
        // }

        const elms3 = document.querySelectorAll(".box>a");
        for (let i = 0; i < elms3.length; i++) {
            elms3[i].style.display = "block";
        }

        const elms4 = document.querySelectorAll(".row-img");
        for (let i = 0; i < elms4.length; i++) {
            elms4[i].style.paddingLeft = "0";
        }
    },
    aapkikhabar: () => {
        Injector.TargetLinkChildAppendToParent("a>div>h2", 2);
        Injector.TargetLinkChildAppendToParent("a>div>h3", 2);
        Injector.TargetLinkChildAppendToParent("div>a>h2.itemTitle", 1);
        Injector.TargetLinkChildAppendToParent("li>a>h2.itemTitle", 1);

        const heading = document.querySelector("h1.story-heading");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const a = document.querySelectorAll(".smartliving-outer");
        for (let e = 0; e < a.length; e++) a[e].style.marginBottom = "42px";
        const b = document.querySelectorAll(".scienceTech-outer");
        for (let e = 0; e < b.length; e++) b[e].style.marginBottom = "42px";
    },
    crictoday: () => {
        Injector.entryTitleAndRecent();
        Injector.TargetLinkAppendToParent("li.jp-related-posts-i2__post-link>a");
    },
    newstodaytimes: () => {
        Injector.postTitleClass();
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");

        const heading = document.querySelector(".single.single-post .page-title>h1");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    deshbandhu: () => {
        Injector.TargetLinkChildAppendToParent("a>.carousel-caption>.carousel-caption-text", 2);
        Injector.TargetLinkAppendToParent("ul.hindi-list-news>li>p>a");
        Injector.TargetLinkChildAppendToParent(".carousel-inner>.item.active>a>p", 1);
        Injector.TargetContainerAppendToParent(".pol-speech>h4");
        Injector.TargetContainerAppendToParent(".content-article>h4");
        Injector.TargetLinkChildAppendToParent("a>div>div>.gallery-title", 3);
        Injector.TargetContainerAppendToParent(".info>.row>.price>h5");
        Injector.TargetLinkAppendToParent(".nstory_header>a.fl-temp");

        const heading = document.querySelector(".nstory_header>h1#divFullHeading");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(".hindi-list-news li p");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "initial";
        }

        const elms2 = document.querySelectorAll(".deshba-hea .content-article.samchar");
        for (let i = 0; i < elms2.length; i++) {
            elms2[i].style.minHeight = "320px";
            elms2[i].style.maxHeight = "320px";
        }
    },
    ghanteonline: () => {
        Injector.kashmirglacier();
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");
    },
    newsganj: () => {
        Injector.titleClass();
        let heading = document.querySelector(".single.single-post h1.entry-title");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }
    },
    vishwajagran: () => {
        Injector.dynamicexpress();
        Injector.TargetLinkAppendToParent(".widget_recent_entries>ul>li>a");
    },
    techshotsapp: () => {
        Injector.TargetLinkAppendToParent(".overlay-imaged>a.m-0");
        Injector.TargetLinkAppendToParent(".overlay>a.m-0");
        Injector.TargetLinkAppendToParent(".w-100.d-flex>a.m-0");
        Injector.TargetLinkAppendToParent(".overlay>a.h4");

        let heading = document.querySelector(".overlay>div>h3.mb-3");
        if (heading && heading.parentNode.querySelectorAll("[qontento-widget]").length === 0) {
            const articleUrl = window.location.href;
            let qontentoDiv = Injector.createQontentoWidget(articleUrl, true);
            heading.parentNode.insertBefore(qontentoDiv, heading.nextSibling);
        }

        const elms = document.querySelectorAll(".d-flex>.w-100.d-flex");
        for (let i = 0; i < elms.length; i++) {
            elms[i].style.height = "140px";
            elms[i].previousElementSibling.style.height = "140px";
        }
    },
};

export default Injector;
