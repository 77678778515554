import { render, Component, options } from "preact";

import MainComponent from "./component";
import EmptyState from "./component/emptyState";

export default class Renderer extends Component {
    constructor(elm, idKey, url, price, options) {
        super();
        this.elm = elm;
        this.idKey = idKey;
        this.url = url;
        this.price = price;
        this.options = options;
    }

    updatePrice(price) {
        this.price = price;
        this.render();
    }

    render() {
        const app = (
            <MainComponent
                url={this.url}
                idKey={this.idKey}
                price={this.price}
                options={this.options}
            />
        );
        render(app, this.elm);
    }
    renderEmpty() {
        const app = <EmptyState />;
        render(app, this.elm);
    }
}
