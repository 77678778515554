import { useEffect } from "preact/hooks";
import Popup from "./popup";

const Modal = ({ options, modal, popupLeft, articlePrice, priceDirection, url, popupTop }) => {
    // logic was added to wordpress website
    useEffect(() => {
        let parentDiv = document?.getElementsByClassName("td_with_ajax_pagination");

        // removed conditionals as they were not behaving properly on mobile
        for (let i = 0; i < parentDiv.length; i++) {
            parentDiv[i].style.overflow = "visible";
        }
    }, []);

    // to remove transform property which messes up zindex
    useEffect(() => {
        let parentDiv = document?.getElementsByClassName("td_with_ajax_pagination");
        for (let i = 0; i < parentDiv.length; i++) {
            parentDiv[i].style.transform = "none";
        }
    }, []);

    // to remove transform property which messes up zindex for leisurebyte
    useEffect(() => {
        let parentDiv = document?.getElementsByClassName(
            "td_block_inner tdb-block-inner td-fix-index"
        );
        for (let i = 0; i < parentDiv.length; i++) {
            parentDiv[i].style.overflow = "visible";
            parentDiv[i].style.transform = "none";
        }

        // remove zindex
        let singleDiv = document?.getElementsByClassName("td-module-meta-info");
        for (let i = 0; i < singleDiv.length; i++) {
            singleDiv[i].style.zIndex = "unset";
        }
    }, []);

    return (
        <>
            {options?.popup && (
                <>
                    {modal && (
                        <div
                            className="qx-widget-popup"
                            style={{
                                position: "absolute",
                                top: `${popupTop}px`,
                                left: `${popupLeft}` + "px",
                                zIndex: 12000,
                            }}
                        >
                            <Popup
                                url={url}
                                price={articlePrice}
                                options={options}
                                priceDirection={priceDirection}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Modal;
