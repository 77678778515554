import { useEffect, useState } from "preact/hooks";
import DemandSection from "./DemandSection";
import Filters from "./Filters";

import GraphPage from "./Graph";
import { urlsAPI, chandlechartAPI } from "./utils";
import Accuracy from "./Accuracy";
import BrandSection from "./BrandSection";
import analyticsRust from "../analyticsRust";

const Popup = ({ url, price, options, priceDirection }) => {
    const [graphData, setGraphData] = useState([]);
    const [timeFilter, setTimeFilter] = useState("1d");
    const [range, setRange] = useState("");
    const [valueMarginTop, setMarginTop] = useState(0);

    const getGraphData = async () => {
        setGraphData([]);
        // Storing response
        const response = await fetch(chandlechartAPI, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: options.currency,
                url: url,
                duration: timeFilter,
            }),
        });

        // Storing data in form of JSON
        var data = await response.json();
        if (data.ok) {
            let _data = data;
            _data = _data.data;

            setGraphData(_data);
        }
    };

    useEffect(() => {
        getGraphData();
    }, [timeFilter, url]);

    useEffect(() => {
        let min = range[0];
        let max = range[1];
        if (min !== "Nan" && max !== "Nan") {
            const current = graphData[0]?.low;
            const height = 84;

            const range = max - min;
            const rangePerPixel = height / range;
            const diff = max - current;
            const spacing = diff * rangePerPixel;

            setMarginTop(spacing);
        }
    }, [range]);

    useEffect(() => {
        analyticsRust.graphEvent({
            articleUrl: url,
            subtype: "open",
            price: price,
            currency: options.currency,
        });
    }, [url]);

    return (
        <div
            style={
                options.theme === "light"
                    ? {
                          backgroundColor: "#fff",
                          border: "1px solid #eee",
                          color: "#000",
                          borderRadius: "6px",
                          overflow: "hidden",
                      }
                    : {
                          backgroundColor: "#000000",
                          borderRadius: "6px",
                          overflow: "hidden",
                      }
            }
            className="qx-foldershape"
        >
            {/* price and graph section */}
            <Filters
                options={options}
                timeFilter={timeFilter}
                setTimeFilter={setTimeFilter}
                price={price}
                priceDirection={priceDirection}
                url={url}
            />

            <div
                className="qx-widget-price-section"
                onClick={() => {
                    analyticsRust.graphEvent({
                        articleUrl: url,
                        subtype: "click",
                        price,
                        element: "graph",
                    });
                }}
            >
                <span style={{ minWidth: "34px", textAlign: "right", marginTop: valueMarginTop }}>
                    {options.currency === "inr" && "₹"}
                    {options.currency === "usd" && "$"}
                    {options.currency === "q" && (
                        <svg
                            style={{ marginRight: "3px", verticalAlign: "middle" }}
                            width="10"
                            height="10"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.4791 16.1741C17.2922 16.4144 17.0884 16.6457 16.8676 16.8664C16.6462 17.0878 16.4142 17.2921 16.1733 17.4794L13.897 15.2033L11.835 17.2652L13.3877 18.8179C10.9812 19.395 8.34025 18.7445 6.46208 16.8664C3.58867 13.9932 3.58867 9.33483 6.46208 6.46163C9.33548 3.58842 13.9942 3.58842 16.8676 6.46163C18.7465 8.3404 19.3968 10.9825 18.8185 13.3896L17.2647 11.8359L15.2026 13.8978L17.4791 16.1741ZM20.5396 19.2345C20.3414 19.4664 20.1325 19.6924 19.9131 19.9117C19.6931 20.1317 19.4665 20.3411 19.234 20.5398L20.6323 21.9381L18.5702 24L16.7399 22.1698C12.3959 24.2681 7.02049 23.5154 3.41655 19.9117C-1.13885 15.3567 -1.13885 7.9714 3.41655 3.41632C7.97196 -1.13877 15.3577 -1.13877 19.9131 3.41632C23.5177 7.02066 24.2701 12.397 22.1702 16.7411L23.9999 18.5707L21.9378 20.6326L20.5396 19.2345Z"
                                fill="white"
                            ></path>
                        </svg>
                    )}
                    {graphData[0]?.close.toFixed(2)}
                </span>
                <GraphPage
                    priceDirection={priceDirection}
                    graphData={graphData}
                    setRange={setRange}
                    timeFilter={timeFilter}
                />
            </div>
            <Accuracy url={url} price={price} options={options} />
            {/* demand section */}

            <BrandSection url={url} price={price} options={options} />
        </div>
    );
};

export default Popup;
