import Renderer from "./render";
import Injector from "./Injector";

import Emitter from "./event";
import { articlePriceAPI } from "./component/utils";
import analyticsRust from "./analyticsRust";

export default class QXWidget {
    constructor(options) {
        if (options && Object.values(options).length) {
            this.options = options;
        } else {
            this.options = {
                theme: "dark",
                demand: true,
                popup: true,
                currency: "inr",
            };
        }
        this.articleUrls = [];
        this.environment = "default";
    }

    // Function to start analytics page visit event
    // Then it inject our code into publication's articles
    // after that it get urls from our injected code
    // after that it gets prices of all the urls after then it shows the prices
    async load() {
        analyticsRust.trackPageView(window.top.location.href, this.options.currency);
        this.addPopupContainer();
        this.addCss();
        Injector.inject(this.environment);
        const urls = this.getUrls();
        const prices = await this.getPrices(urls);
        this.render(prices);
        // this.addEmpty(urls, prices);

        setTimeout(this.reloadPrices(), 3000);

        // incase of newzbangla or hellomumbai check for new articles added to the page
        if (
            this.environment === "newzbangla" ||
            this.environment === "hellomumbai" ||
            this.environment === "techoutlook" ||
            this.environment === "hellowomeniya" ||
            this.environment === "wordpress" ||
            this.environment === "popdiaries" ||
            this.environment === "jantaserishta" ||
            this.environment === "hindiwebnews24" ||
            this.environment === "timesofmalwa" ||
            this.environment === "timesofmalwahindi" ||
            this.environment === "inanews" ||
            this.environment === "vcworld" ||
            this.environment === "atulyaloktantranews" ||
            this.environment === "suspensecrime" ||
            this.environment === "upkiran" ||
            this.environment === "e2newz" ||
            this.environment === "ibgnews" ||
            this.environment === "newsedge360" ||
            this.environment === "realtimes" ||
            this.environment === "jansandeshonline" ||
            this.environment === "risenshine" ||
            this.environment === "blockchainmagazine" ||
            this.environment === "salahuddinayyubi" ||
            this.environment === "newznew" ||
            this.environment === "theindiabizz" ||
            this.environment === "moviegabbit" ||
            this.environment === "newsblare" ||
            this.environment === "techtimes" ||
            this.environment === "glowaglitter" ||
            this.environment === "charchitnews" ||
            this.environment === "xtratime" ||
            this.environment === "democracynewslive" ||
            this.environment === "bullishafnews" ||
            this.environment === "realitynews" ||
            this.environment === "worsonlinenews" ||
            this.environment === "fastnewsindia" ||
            this.environment === "hdmovies" ||
            this.environment === "janmatmarathi" ||
            this.environment === "krishijagran" ||
            this.environment === "wittyflick" ||
            this.environment === "janchowk" ||
            this.environment === "indianewsviral" ||
            this.environment === "dynamicexpress" ||
            this.environment === "cityandolan" ||
            this.environment === "cricketnmore" ||
            this.environment === "aapkikhabar" ||
            this.environment === "crictoday" ||
            this.environment === "deshbandhu" ||
            this.environment === "default"
        ) {
            setTimeout(this.checkForNewArticles(), 3000);
        }
    }

    // Function to get urls from webpage
    getUrls() {
        const elms = document.querySelectorAll("[qontento-widget]");
        const urls = [];
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            urls.push(elm.getAttribute("data-url"));
        }
        this.articleUrls = urls;
        return urls;
    }

    // Function to get prices of the urls
    getPrices = async (urls) => {
        if (urls.length > 0) {
            try {
                const prices = {};

                // Storing response
                const response = await fetch(articlePriceAPI, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    // /make sure to serialize your JSON body
                    body: JSON.stringify({
                        currency: this.options.currency,
                        urls: urls,
                    }),
                });

                // Storing data in form of JSON
                var data = await response.json();

                urls.forEach((url) => {
                    prices[url] = data[url];
                });
                return prices;
            } catch (err) {
                return {};
            }
        } else {
            return {};
        }
    };

    // Function to send url and price to every single article that has price
    render(prices) {
        Object.keys(prices).forEach((url) => {
            this.renderSingle(url, prices[url]);
        });
    }

    // Function to show price in the article
    renderSingle(url, price) {
        if (!price) return;
        const elms = document.querySelectorAll(`[qontento-widget][data-url="${url}"]`);
        elms.forEach((elm) => {
            if (!elm.hasChildNodes() || elm.querySelector(".qx-widget-article-no-price")) {
                const postfix = Math.random().toString().substr(2, 20);
                const r = new Renderer(elm, postfix, url, price, this.options);
                r.render();
            }
        });
    }

    // Function to reload prices
    async reloadPrices() {
        const prices = await this.getPrices(this.articleUrls);
        this.emit(prices);
        await setTimeout(this.reloadPrices.bind(this), 3000);
    }

    // Function to send new prices to the articles
    emit = (prices) => {
        Emitter.emit("prices", prices);
    };

    // Function to check if new articles have been added to the page and to display prices for them
    async checkForNewArticles() {
        Injector.inject(this.environment);
        const urls = this.getNewUrls();

        // only fetch prices when new url is present
        if (urls.length > 0) {
            const prices = await this.getPrices(urls);
            this.render(prices);
            // this.addEmpty(urls, prices);
        }
        await setTimeout(this.checkForNewArticles.bind(this), 3000);
    }

    // Function to add new article urls to articleUrls
    getNewUrls() {
        const elms = document.querySelectorAll("[qontento-widget]");
        const urls = [];
        for (let i = 0; i < elms.length; i++) {
            const elm = elms[i];
            if (this.articleUrls.indexOf(elm.getAttribute("data-url")) === -1) {
                urls.push(elm.getAttribute("data-url"));
                this.articleUrls.push(elm.getAttribute("data-url"));
            } else if (!elm.hasChildNodes() || elm.querySelector(".qx-widget-article-no-price")) {
                urls.push(elm.getAttribute("data-url"));
            }
        }
        return urls;
    }

    // Function to add empty state
    addEmpty(urlList, prices) {
        urlList.forEach((url) => {
            this.addEmptySingle(url, prices[url]);
        });
    }

    addEmptySingle(url, price) {
        if (price) {
            return;
        }
        const elms = document.querySelectorAll(`[qontento-widget][data-url="${url}"]`);
        elms.forEach((elm) => {
            if (!elm.hasChildNodes()) {
                const postfix = Math.random().toString().substr(2, 20);
                const r = new Renderer(elm, postfix, url, price, this.options);
                r.renderEmpty();
            }
        });
    }

    addPopupContainer() {
        if (document.querySelectorAll("#qx-popup-container").length === 0) {
            const qxPopupWidget = document.createElement("div");
            qxPopupWidget.id = "qx-popup-container";
            document.body.appendChild(qxPopupWidget);
        }
    }
    addCss() {
        if (
            document.querySelectorAll("script#kunato_js-js").length === 1 &&
            document.querySelectorAll("link#kunato_css-css").length === 0
        ) {
            const linkHref = document
                .querySelectorAll("script#kunato_js-js")[0]
                .src.replace("widget.js", "widget.css");
            const linkTag = document.createElement("link");
            linkTag.rel = "stylesheet";
            linkTag.href = linkHref;
            linkTag.id = "kunato_css-css";

            document.head.append(linkTag);
        }
    }
}
