import { h } from "preact";

const SvgIcons = ({ priceDirection }) => {
    return (
        <>
            {priceDirection === "up" && (
                <svg
                    height="0.75em"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px" }}
                >
                    <path
                        d="M6.49999 10.2916L6.49999 2.70825M6.49999 2.70825L2.70832 6.49992M6.49999 2.70825L10.2917 6.49992"
                        stroke="#25ca83"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            )}
            {priceDirection === "down" && (
                <svg
                    height="0.75em"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px" }}
                >
                    <path
                        d="M6.49999 2.70841L6.49999 10.2917M6.49999 10.2917L2.70832 6.50008M6.49999 10.2917L10.2917 6.50008"
                        stroke="#F14B63"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            )}
        </>
    );
};

export default SvgIcons;
