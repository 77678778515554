import analyticsRust from "../../analyticsRust";

const BrandSection = ({ url, price, options }) => {
    const handleClick = () => {
        analyticsRust.graphEvent({
            articleUrl: url,
            subtype: "click",
            price,
            element: "logo",
        });

        window.open("https://www.kunato.ai/", "_blank");
    };

    return (
        <div className="qx-widget-brand-section">
            <div
                className="qx-widget-brand-name"
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
            >
                <a href="https://www.kunato.ai/" target="_blank">
                    <span>
                        Priced by <b>Kunato.Ai</b>
                    </span>
                </a>
            </div>
        </div>
    );
};

export default BrandSection;
