import { h } from "preact";
import { Suspense, lazy } from "preact/compat";

const SomeComponent = lazy(() => import("../CandleGraph/index"));

export default (props) => {
    return (
        <Suspense fallback={"loading.."}>
            <SomeComponent {...props} />
        </Suspense>
    );
};
// Usage
